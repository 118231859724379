<template>
  <div>
    <div
      v-imloading="loading"
      class="h-full overflow-hidden site-wrapper"
      :class="{[prefixCls]: 1,}"
      :style="getStyle"
    >
      <div
        v-show="isShowBackgroundImage(computeGlobalBackgroundStyle)"
        class="site-back-ground-image"
        :style="computeGlobalBackgroundStyle"
      ></div>
      <div v-show="isShowColor(model.globalSetting)" class="site-background-color" />
      <div id="site-body" :key="playerId" class="h-full overflow-auto site-content relative">
        <module-navigation-widget :model="model.moduleNavigationSetting" :site="model" :device="device" />
        <template v-for="(w,index) in model.widgetList">
          <component
            :is="w.name.replace('Model', '')"
            v-if="w.visible"
            :id="w.id"
            :key="w.id + index"
            class="widget-item"
            :model="w"
            :site="model"
            :device="device"
            :editing="false"
          />
        </template>
      </div>
      <site-modals :device="device" :site="model" />
    </div>
    <CheckWeChat v-if="isShowWeChatModal" />
    <order-modal />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import cloneDeep from "lodash.clonedeep";
import { computeBackgroundStyle, isShowColor, isShowBackgroundImage } from '~/site/model/helper'
import { useGlobSetting } from "~/hooks/useGlobSetting"
import { widgetComponents } from '~/site/model/components'
import { DefaultMobileDeviceWidth, DeviceClassMapping } from '~/settings/deviceSetting'
import { DeviceEnum } from '~/enums/deviceEnum'
import { isWeChat } from '~/utils/types'
import PwaWidget from "~/site/widgets/pwaWidget/index.vue"
import SiteModals from '~/site/components/modals'
import { PageEnum } from '~/enums/pageEnum'
import EventTrackerMixins from '~/mixins/eventTracker'
import CheckWeChat from '~/site/components/checkWeChat'
import Bus from "~/site/model/bus"
import OrderModal from "~/site/components/orderModal/index.vue";

export default {
  name: "SiteIndex",
  components: {
    OrderModal,
    SiteModals,
    CheckWeChat,
    ...widgetComponents,
    PwaWidget
  },

  mixins: [EventTrackerMixins],

  props: {
    model: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },

    whiteList: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    const { prefixCls } = useGlobSetting()

    return {
      prefixCls: prefixCls + '-preview',
      // deviceClass: DeviceClassMapping[DefaultDevice],
      loading: false,
      computeBackgroundStyle,
      isShowColor,
      isShowBackgroundImage,
      isShowWeChatModal: false
    }
  },
  computed: {
    getStyle() {
      let style = {}
      const { globalSetting } = this.model
      if (globalSetting) {
        style = globalSetting.__vars
      }
      this.$parent?.$parent?.$emit('change-style', style)
      return style
    },
    ...mapState({
      device: state => state.device?.device,
      playerId: state => state.user?.uuid,
      projectInfo: (state) => state.project.info,
    }),
    computeGlobalBackgroundStyle() {
      if (!this.model && this.model.globalSetting) {
        return {}
      }
      const gSetting = { ...this.model.globalSetting }
      const bgImage = gSetting.backgroundImage
      if (this.device === DeviceEnum.MOBILE) {
        Object.assign(gSetting, gSetting.mobileBackground)
        if (!gSetting.backgroundImage) {
          gSetting.backgroundImage = bgImage
        }
      }
      return computeBackgroundStyle(gSetting)
    },
    whiteListComputed() {
      return this.whiteList
    }
  },
  watch: {},
  async mounted() {
    this.isShowWeChatModal = isWeChat()
    // this.showPwa()
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      await this.init()
      window.BUILD_VERSION = process.env.BUILD_VERSION
    }
  },
  beforeDestroy() {
    Bus.$off('reloadActivity')
  },
  methods: {
    async init() {
      this.setDeviceWidth()
      await this.getIpInfo()
      this.$store.commit('site/SET_GLOBAL_VARS', cloneDeep(this.getStyle))
    },
    getIpInfo() {
      this.whiteListHandler()
      const from = this.$route.query?.from
      this.eventTracker(1, from)
    },
    resizeMethod(event) {
      const w = this.__$body.clientWidth
      if (w <= DefaultMobileDeviceWidth) {
        this.deviceClass = DeviceClassMapping[DeviceEnum.MOBILE]
        this.device = DeviceEnum.MOBILE
        this.$store.commit('editor/SET_DEVICE', DeviceEnum.MOBILE)
      } else {
        this.deviceClass = DeviceClassMapping[DeviceEnum.DESKTOP]
        this.device = DeviceEnum.DESKTOP
        this.$store.commit('editor/SET_DEVICE', DeviceEnum.DESKTOP)
      }
      this.setDeviceWidth()
    },

    setDeviceWidth() {
      this.$nextTick(() => {
        this.$store.commit('editor/SET_DEVICE_WIDTH',
          document.querySelector('#site-body')?.clientWidth)
      })
    },

    /**
     * 当前 ip所在的 ip_country不在白名单中，则跳转到无权限页面
     * 不在 node端直接拦截，是因为项目支持通过 x-forward-for切换 ip
     *
     * @param {*} result
     */
    whiteListHandler() {
      console.log('whiteListHandler', this.whiteListComputed)
      if (this.whiteListComputed?.length) {
        const isInWhiteList = this.$store.state.user.ipInfo.ip_country
          ? this.whiteListComputed?.includes(this.$store.state.user.ipInfo.ip_country)
          : false
        if (!isInWhiteList) {
          console.log(`FORBIDDEN_PAGE isInWhiteList: ${isInWhiteList}`)
          this.$router.push(PageEnum.FORBIDDEN_PAGE)
        }
      }
    },
  }
}
</script>

<style lang="less" scoped>
.site-wrapper {
  min-height: 100%;
  overflow-y: auto;
  position: relative;
  .site-back-ground-image {
    width: 100vw;
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
  }
  .site-background-color {
    background: var(--background-color);
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    left: 0;
  }
  .widget-item {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .site-content {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
