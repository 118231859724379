<template>
  <div class="footer-widget-edit-wrap">
    <menu-background :model="model" background-blur-disabled :show-title="false" image-key="backgroundImage" slider-class="pb-0" />
    <menu-divider />
    <menu-item :label="$t('model.footerImage')" size-tip="(建议比例4:4)">
      <menu-background-image :target="model.logoBackground" target-key="logoBackground" />
    </menu-item>
    <menu-item class="menu-item-switch" pt="0px" label="字体颜色">
      <menu-fill
        :target="model"
        color-key="footerFontColor"
        eye-key="footerFontColorShow"
      />
    </menu-item>
    <!-- 分割线 -->
<!--    <menu-item>-->
<!--      <menu-background-->
<!--        :background-blur-disabled="true"-->
<!--        :image-disabled="true"-->
<!--        :background-name="$t('menu.splitLine')"-->
<!--        :model="model"-->
<!--        color-key="splitLineColor"-->
<!--        color-eye-key="showSplitLine"-->
<!--      ></menu-background>-->
<!--    </menu-item>-->
  </div>
</template>

<script>
import { FooterWidgetModel } from '../../model'
import MenuItem from "~/components/menu/menuItem";
import MenuBackground from '~/components/menu/menuBackground'
import menuBackgroundImage from "~/components/menu/menuBackgroundImage";
// import MenuSwitch from "~/components/menu/menuSwitch";
import menuFill from "~/components/menu/menuFill"
import MenuDivider from "~/components/menu/divider.vue";

export default {
  name: "FooterWidgetEdit",
  components: {
    MenuDivider,
    MenuItem,
    MenuBackground,
    menuBackgroundImage,
    // MenuSwitch,
    menuFill,
  },
  props: {
    model: {
      type: Object,
      default() {
        return new FooterWidgetModel()
      }
    }
  },
  methods: {
    handleShowBackgroundMenu(key, model) {
      this.$parent && this.$parent.selectSubmenu(key || 'background', model || this.model, {
        imageKey: 'backgroundImage',
        colorKey: ''
      })
    },
    setFixed() {
      this.model.fixed = !this.model.fixed
    }
  }
}
</script>

<style lang="less" scoped>
.footer-widget-edit-wrap {
  .check {
    span {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      margin: 0 8px;
    }
  }
}
</style>
